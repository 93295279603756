import React from 'react'

import RequestsHandler from '../RequestHandler/RequestsHandler'

const MyRequests = () => {
  
  return (
    <RequestsHandler requestTarget={"user"}/>
  )
}


export default MyRequests
